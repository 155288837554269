<template>
	<div id="plantDetail">
		<page-plant-detail :is-shop="0"></page-plant-detail>
	</div>
</template>

<script>
	import pagePlantDetail from '@/components/layout/land/page-plant-detail.vue'
	export default{
		components:{
			pagePlantDetail
		}
		
	}
</script>

<style lang="scss">
</style>
